import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => {
  const imageMina = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "profile.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 413, maxWidth: 310) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        description="Welcome to Mina's Elderly Care Homes! Learn about Mina and services offered at these wonderful southern Silicon Valley care homes, where patients are family."
        title="Home"
      />
      <h1>Welcome</h1>
      <div className="grid-two third-to-two-third">
        <Img fluid={imageMina.placeholderImage.childImageSharp.fluid} />

        <p style={{ display: 'flex', alignItems: 'center' }}>
          My name is Mina Abbasvand, and I am the owner of Mina's Elderly Care Homes. We are located
          in Los Gatos, Campbell, and Santa Teresa. I have over two decades of nursing experience,
          and I have worked as a hospice nurse for ten years. I envision my homes to offer the best
          quality of care for you and your family. Working alongside my husband Michael, my
          caregivers, and the rest of my dedicated staff, I guarantee my clients and their families
          a positive, supportive, and family-like environment. We hope to have the honor of meeting
          with you soon.
        </p>
      </div>
    </Layout>
  );
};

export default IndexPage;
